import AzApi from 'o365.modules.azure.storage.api.ts';

export default class VersionSourceProvider{
    fileName:string;
    type:string;
    azBlobClient:any;// = AzApi.BlobClient.StaticFiles;
    templatesClient: any;
    constructor(pFileName:string, pType:string = "files"){
        this.fileName = pFileName;
        this.type = pType;
        this.azBlobClient =   new AzApi.BlobClient({
            accountName: 'omega365appcache',
            entityName: 'o365-apps',
        });
        this.templatesClient =  new AzApi.TableClient({
            accountName: 'omega365appcache',
            entityName: 'apps',
        });
    }

    async getSourceData(pPath:string){
        try{
            const result = await this.azBlobClient.getBlobJson({fileNameWithPath: `${this._getLocation(pPath)}`});
            if (typeof result === "string") return ({ Content: result });
            return result;
        }catch (ex) {
            console.log(ex);
            return { Content:"Error. Failed to read "}
        }

        //for app files gather everything just from source
        //need to fix et path
    }

    async getTemplateSourceData(pFileName:string, pVersion: number){
        try{
            const result= await this.templatesClient.queryTableEntities({filter:`PartitionKey eq '${pFileName}' and Version eq ${pVersion}`});  
            return await this.getSourceData(result.data[0].Location)
        }catch (ex) {
            console.log(ex);
            return { Content:"Error. Failed to read "}
        }

    }

    private _getLocation(pLocation:string){
        const vTmp = pLocation.split("/");
        if(vTmp[1] === "site"){
            const vVersion = parseInt(vTmp.splice(3,1)[0]);
            if(isNaN(vVersion)){
                return pLocation.replace("gz","source.gz")
            }
            vTmp.push(vVersion.toString());
            return vTmp.join("/").replace(".gz","")+"/"+this.fileName+".source.gz";
        }

        if(this.type === "template") {
            return pLocation;
        }

        return pLocation.replace("json.gz","")+"source.json.gz";
    }

}